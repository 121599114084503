import React from "react"
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import { Link } from "gatsby"
import { request } from '../../lib/api'

const Product = ({ product, reload }) => {

  const formatImg = (url) => {
    if ( url === null || url === undefined || url === '') { return null }
    if ( url.indexOf('http') > -1 ) { return url }
    if ( url.split('/').length === 1 ) { url = 'assets/img/' + url }
    return `https://wastewaterdepot.com/${url}`
  }

  // const activate = ()=>{
  //   request('POST', '/wwd/products/activate', {id: product._id}).then((res)=>{
  //     reload()
  //   }).catch((e)=>console.log(e))
  // }

  const deactivate = ()=>{
    request('POST', '/wwd/products/deactivate', {id: product._id}).then((res)=>{
      reload()
    }).catch((e)=>console.log(e))
  }
  const deleteProd = ()=>{
    request('POST', '/wwd/products/delete', {_id: product._id}).then((res)=>{
      reload()
    }).catch((e)=>console.log(e))
  }

  return (
    <Card style={{ margin: "15px auto" }} id={product._id} >
      {
        product.src && product.src !== null && product.src !== '' ?
          ( <Card.Img variant="top" src={formatImg(product.src)} /> ) :
          ( <Card.Img variant="top" src="/coming_soon.png" /> )
      }
      <Card.Body>
        <Card.Title>{product.name}</Card.Title>

        <ButtonToolbar aria-label="Toolbar with button groups">
          <ButtonGroup className="mr-2" aria-label="First group">
            <Link to={`/product/update?id=${product._id}`} >
              <Button variant="primary">Update</Button>
            </Link>
          </ButtonGroup>

          <ButtonGroup className="mr-2" aria-label="Second group">
            <Button variant="danger" onClick={deactivate}>DEACTIVATE</Button>
          </ButtonGroup>

          <ButtonGroup className="mr-2" aria-label="Last group">
            <Button variant="danger" onClick={deleteProd}>DELETE</Button>
          </ButtonGroup>
        </ButtonToolbar>
        
      </Card.Body>
    </Card>
  )
}

export default Product