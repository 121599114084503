import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Product from "../components/products/product"
import { request } from '../lib/api'

const Products = () => {

  const [products, setProducts] = useState(null)
  const getProducts = () => {
    request('GET', '/wwd/products').then((e)=>{
      setProducts(e.products)
    })
  }

  useEffect(()=>{
    if (!products) {
      getProducts()
    }
  })

  return (
    <Layout>
      <SEO title="Products" />
      <div className="row">
        <div className="col"> <h2>All products</h2> </div>
      </div>
      <div className="row">
          {
            products && products.map((product, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                <Product product={product} reload={getProducts} />
              </div>
            ))
          }
      </div>
    </Layout>
  )
}

export default Products
